#slider-container {
  width: 100%;
  height: 570px;
  overflow: hidden;
  position: relative;
  float: left;
  display: block;
  #slider {
    width: 100%;
    height: 100%;
    min-height: 570px;
    position: relative;
    overflow: hidden;
    div:not(.owl-controls) {
      height: 100%;
      position: relative;
    }
    .owl-item {
      height:100%;
      position:relative;
    }
    .owl-slide {
      z-index: 100;
      position: relative;
    }
  }
}
.overlay {
  position:relative;
  &:after {
    content:"";
    width:100%;
    height:100%;
    display:block;
    background:rgba(0,0,0,0.25);
    position:absolute;
    left:0;
    top:0;
    z-index:3;
  }
}