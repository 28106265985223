.navbar-top {
  width:100%;
  background:$blue;
  color:white;
  position:fixed;
  top:0;
  left:0;
  right:0;
  height:25px;
  font-weight:300;
  line-height:25px;
  vertical-align: middle;
  font-size:16px;
  z-index:1000;
  .contact {
    float:left;
  }
  .language {
    float:right;
    .navbar-nav {
      margin:0;
      padding:0;
      li {
        padding:0;
        margin:0;
        a {
          padding:0 15px 0 0;
          margin:0;
          font-size:16px;
          font-family: $font-standard;
          color:white;
          font-weight:300;
          line-height:25px;
          vertical-align: middle;
          i {
            font-size:11px;
            position:absolute;
            right:0;
            top:5px;
          }
          &:hover,
          &:focus {
            background:transparent;
            color:$green;
          }
        }
        .dropdown-menu {
          min-width:50px;
          background:$blue;
          -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0);
          box-shadow: 0 6px 12px rgba(0,0,0,0);
          border-radius:0;
          right:0;
          left:auto;
          li {
            a {
              padding:3px 8px;
              text-align:left;
              color:white;
              &:hover,
              &:focus {
                color:$green;
              }
            }
          }
        }
      }
    }
  }
  a {
    color:white;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

}