@media only screen and (max-width : 1499px) {
  .container-fluid {
    max-width:1300px
  }
  .list {
    .item {
      .thumbnail {
        .extra {
          height:400px;
          min-height:400px;
          .short {
            min-height:225px;
            height:225px;
          }
        }
      }
    }
  }
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        width:350px;
        margin-top:15px;
      }
    }
    .navbar-right {
      margin-top:30px;
      li {
        a {
          font-size:20px;
          padding:12px 0;
          margin:0 18px;
        }
        &.dropdown-large {
          .dropdown-menu-large {
            max-width:1300px;
          }
        }
      }
    }
    &.stick {
      height:88px;
      top:25px;
      .navbar-right {
        margin-top:21px;
      }
    }
  }
}
@media only screen and (max-width : 1339px) {
  .container-fluid {
    max-width:1170px
  }
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        width: 300px;
      }
    }
    .navbar-right {
      li {
        &.dropdown-large {
          .dropdown-menu-large {
            max-width:1170px;
          }
        }
      }
    }
  }
  .list {
    .item {
      .thumbnail {
        .extra {
          height:445px;
          min-height:445px;
          .short {
            min-height:270px;
            height:270px;
          }
        }
      }
    }
  }
}