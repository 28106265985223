.sidebar {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
  width: 100%;
  max-width:320px;
  background: white;
  zoom: 1;

  a {
    display: block;
    padding: 15px 20px;
    color: $blue;
    font-size: 18px;
    text-decoration: none;
  }

  ul.products-list {
    position: relative;
    z-index: 597;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      list-style: none;
      margin: 0;
      padding: 0;
      float: none;
      min-height: 1px;
      line-height: 1em;
      vertical-align: middle;
      position: relative;
      background:$grey-light;

      a {
        border-right: 4px solid $blue;
        color: $blue;

        &:hover {
          background: $blue;
          color: white;
        }
      }

      &:hover,
      .hover {
        position: relative;
        z-index: 599;
        cursor: default;
        color: #ffffff;

        ul {
          visibility: visible;
        }

        a {
          background: $blue;
          color: white;
        }
      }

      &.active {
        a {
          background: $blue;
          color: white;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      visibility: hidden;
      position: absolute;
      z-index: 598;
      width: 100%;
      top: 0px;
      left: 99%;
      border: 1px solid $blue;
      -webkit-border-radius: 0 3px 3px 0;
      -moz-border-radius: 0 3px 3px 0;
      border-radius: 0 3px 3px 0;

      a {
        font-size: 18px;
        color: #ffffff;

        &:hover {
          color: #ffffff;
        }
      }

      li {
        float: none;
        font-weight: normal;
        a {
          border-right:0px solid $blue;
        }
        &:hover {
          a {
            background: $grey-light;
            color: $blue;
          }
        }
      }

      ul {
        top: -2px;
        right: 0;
      }
    }
  }

  &:before {
    content: '';
    display: block;
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.sidebar ul li.has-sub > a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -9px;
}

.sidebar ul li.has-sub ul li.has-sub > a:after {
  content: '';
}

.search-block {
  display:block;
  overflow:auto;
  margin:0 0 30px 0;
  position:relative;
  padding:0px;
  background:white;
  h4 {
    font-weight:400;
    font-size:21px;
    margin:0 0 10px 0;
  }
  .navbar-form {
    padding:0;
    margin:0;
    .form-control {
      width:100%;
      background:white;
      position:relative;
      z-index:0;
    }
    .form-group.search {
      position:relative;
    }
  }
  #btn-search-large {
    width:54px;
    height:54px;
    border:0;
    border-radius:5px;
    background:$blue;
    color:white;
    position:absolute;
    top:1px;
    right:1px;
    z-index:20;
    transition:background ease-in-out 0.25s;
    i {
      font-size:21px;
      color:white;
    }
    &:hover,
    &:focus {
      background:$blue-dark;
      color:white;
    }
  }
}
