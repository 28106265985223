@media only screen and (max-width: 767px) {
  .navbar-fixed-top {
    height:75px;
    transition:ease all 0.25s;
    position:fixed;
    top:25px;
    box-shadow:0 4px 4px -2px rgba(0, 0, 0, 0.1);
    background:white;
    button {
      background: transparent;
      border: 0;
      float: right;
      width: 45px;
      height: 35px;
      position: relative;
      margin: 22px 20px 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 4px;
        border-radius:4px;
        width: 100%;
        background: $blue;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2) {
        top: 12px;
      }

      span:nth-child(3) {
        top: 24px;
      }
    }
    .menu-toggle.open span:nth-child(1) {
      top: 12px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    .menu-toggle.open span:nth-child(3) {
      top: 12px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
    .navbar-header {
      .brand {
        margin-left: 20px;
        margin-top:15px;
        width:200px;
        transition:ease all 0.25s;
        img {
          height:40px !important;
          width:200px !important;
        }
      }
    }
    #navbar {
      border-color:transparent;
      border:0;
      .navbar-right {
        margin:7.5px -15px;
        background:white;
        -webkit-transition: none;
        -moz-transition: none;
        -o-transition: none;
        transition: none;
        li {
          border-bottom:1px solid $grey-light;
          &:first-child {
            display:block;
          }
          a {
            color:$blue;
            text-shadow:none;
            -webkit-transition: none;
            -moz-transition: none;
            -o-transition: none;
            transition: none;
            &:before {
              content:"";
              width:0;
              height:0;
              -webkit-transition: none;
              -moz-transition: none;
              -o-transition: none;
              transition: none;
            }
            &:hover,
            &:focus {
              &:before {
                content:"";
                width:0;
                height:0;
                -webkit-transition: none;
                -moz-transition: none;
                -o-transition: none;
                transition: none;
              }
            }
          }
          &.active {
            background:$blue;
            a {
              text-shadow:none;
              color:white;
            }
          }
          &.dropdown-large {
            .dropdown-menu-large {
              padding:0;
              width:100%;
              li {
                background:lighten($blue,60%);
                a {
                  text-transform: lowercase;
                }
                &:last-child {
                  a {
                    border:0;
                  }
                }
              }
            }

          }
        }
      }
    }
    .navbar-nav > .open > a,
    .navbar-nav > .open > a:focus,
    .navbar-nav > .open > a:hover {
      background:transparent;
    }
    &.stick {
      top:25px;
      height:75px;
      .navbar-header {
        .brand {
          width:200px;
          margin-top:15px;
        }
      }
      button {
        span {
          background:$blue;
        }
      }
    }
  }

  #slider-container {
    height:210px;
    margin-top:95px;
    #slider {
      min-height:210px;
      .owl-slide {
        height:210px !important;
      }
    }
  }
  header {
    #header-image {
      height:210px !important;
    }
  }

  h1 {
    font-size:32px;
  }

  .contact {
    h1 {
      margin:0 0 30px 0;
    }
  }

  section.padded {
    padding:45px 8px;
  }
  #home-catalog {
    padding:45px 8px;
    .download-buttons {
      a {
        display:block;
        width:100%;
        margin:0 0 15px 0;
      }
    }
  }

  #home-products {
    padding:45px 8px;
    h2 {
      margin:0 0 30px;
      text-align:left;
      font-size:36px;
    }
  }

  #product-detail {
    .text-block {
      .tab-container {
        margin:0;
      }
      .tab-content {
        padding:15px 0 0 0;
        display:block;
        overflow:auto;
        p + ul {
          margin:0;
        }
        ul + p {
          margin-top:30px;
        }
        h2 {
          font-size:21px;
        }
        .tab-pane {
          margin-top:25px;
          float:left;
          display:block;
          overflow:hidden;
          .list-photos {
            overflow:hidden;
            display:block;
            li {
              overflow:hidden;
              a {
                margin:0 0 10px 0;
              }
              &:nth-child(odd) {
                padding-right:5px;
              }
              &:nth-child(even) {
                padding-left:5px;
              }
            }
          }
        }
      }
      #omschrijving {
        margin-top:0;
      }
      #specificaties {
        width:100%;
        ul {
          width:100%;
        }
      }
      .nav-tabs {
        li {
          a {
            padding:7px 10px;
            font-size:18px;
          }
        }
      }
    }
  }

  #product-detail {
    .text-block {
      .short-info {
        h1 {
          margin:0;
          font-size:30px;
          border-bottom:1px solid aliceblue;
          padding-bottom:15px;
        }
      }
    }
  }

  .card-list {
    li {
      padding-left:0;
      padding-right:0;
      .thumbnail {
        .owl-slide {
          .image {
            height:200px !important;
          }
        }
        span {
          width:100%;
        }
        &:hover {
          span {
            padding:9px 10px 12px 10px;
            &:after {
              content:none;
            }
          }
        }
      }
    }
    li:nth-child(2n+1) {
      padding-left:0;
      padding-right:0;
    }
    li:nth-child(2n) {
      padding-right:0;
      padding-left:0;
    }
  }

  #news-overview-cards {
    padding:0 20px;
    margin:0 0 30px;
    .masonry-row {
      .item {
        padding:0;
        h2 {
          font-size:26px;
        }
        .desc {
          display:none;
        }
      }
    }
  }

  .list {
    .item {
      .thumbnail {
        .extra {
          height:auto;
          min-height:1px;
          padding:25px 25px 50px 25px;
          .short {
            min-height:1px;
            height:auto;
          }
        }
        .album-image {
          img {
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%);
          }
        }
      }
    }
  }

  #searchForm {
    width:90%;
    .form-control {
      width:100%;
    }
  }

  #contact-info {
    span.address {
      width:100%;
    }
    .contact-list {
      width:100%;
    }
  }

  .form-small,
  .form-medium {
    width:100%;
  }
  .form-small {
    padding-right:0px;
  }
  .form-medium {
    padding-left:0px;
  }
  .form-small + .form-medium {
    margin-top:8px;
  }

  footer {
    .footer-logo {
      text-align:center;
      float:none;
      margin:0 auto 5px auto;
      width:250px;
    }
    .footer-address {
      text-align:center;
      ul {
        display:inline-table;
        margin:0;
        float:none;
        li {
          display:inline-block;
          *display:inline; /*IE7*/
          *zoom:1; /*IE7*/
          width:100%;
          font-size:20px;
          line-height:36px;
          &:before {
            content:""
          }
        }
      }
    }
  }
}