html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}
html {
  height: 100%;
}
body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: $font-standard;
  font-weight: 300;
  font-style: normal;
  color:$grey;
  font-size: 100%;
  padding: 0;
  margin: 0;
}
a {
  outline:0;
  color:$blue;
  font-weight:400;
  &:hover,
  &:focus {
    color:$blue-dark;
    text-decoration: none;
    outline:0;
  }
}
h1, h2, h3, h4, h5, h6 {
  color:$blue;
  margin:0;
  padding:0;
  font-family: $font-standard;
  font-weight:700;
}
h1 {
  font-size:52px;
}
h2 {
  font-size:32px;
}
p {
  font-size:18px;
  line-height:36px;
  font-family: $font-standard;
  margin:0 0 30px 0;
}
.container-fluid {
  width:100%;
  max-width:1476px;
}
section {
  float:left;
  width:100%;
  position:relative;
  &.padded {
    padding:120px 0;
  }
  &.grey {
    background:$grey-light;
  }
  &.pattern {
    background:$grey-light url(/images/pattern-pegasus.png) no-repeat;
    background-position:center center;
    background-size:contain;
    padding:80px 0;
  }
  .text-block {
    width:100%;
    float:left;
    h1 {
      margin:0 0 30px 0;
    }
    h2, h3, h4, h5, h6 {
      margin:0 0 4px 0;
    }
    p, ul, ol, li, blockquote {
      font-size: 18px;
      line-height: 36px;
      font-family: $font-standard;
    }
    p {
      margin:0 0 30px 0;
    }
    ul, ol {
      margin: 30px 0 30px;
      padding:0 0 0 20px;
    }
    ul > li > ul {
      margin:0;
    }
  }
}