$blue:           #206f92;
$blue-dark:      #325874;
$grey:           #545454;
$grey-light:     #f5f5f5;
$green:          #ded662;
$green-dark:     #978f1a;
$red:            #FF3100;

$font-standard:   'Source Sans Pro', sans-serif;

