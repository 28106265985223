.contact-intro {
  ul {
    margin:0 0 45px 0;
    padding:0;
    list-style:none;
    li {
      position:relative;
      padding:5px 0 5px 35px;
      font-size: 18px;
      line-height: 36px;
      font-family: $font-standard;
      &:before {
        content:"\f00c";
        font-family: FontAwesome;
        position:absolute;
        left:0;
        top:4px;
        font-size:22px;
        color:$blue;
      }
    }
  }
}
.table-times {
  margin:10px 0;
  tr {
    td {
      font-size: 18px;
      line-height: 36px;
      font-family: $font-standard;
      padding:0;
      &:first-child {
        width:45px;
      }
      &:last-child {
        font-weight:400;
      }
    }
  }
}

.contact {
  h1 {
    margin:0 0 8px 0;
    display:block;
  }
  .address {
    h3 {
      margin-bottom:10px;
    }
    p {
      margin-bottom:24px;
    }
  }
  .contact-list {
    margin:0;
    padding:0;
    list-style:none;
    li {
      float:left;
      width:100%;
      position:relative;
      font-size:21px;
      margin:5px 0;
      padding:10px 0 10px 65px;
      a {
        color:$blue;
        &:hover,
        &:focus {
          color:$green;
        }
      }
      &:before {
        content:"";
        font-family: FontAwesome;
        position:absolute;
        left:0;
        top:3px;
        padding-top:5px;
        border:3px solid $blue;
        border-radius:50%;
        color:$blue;
        font-size:20px;
        width:45px;
        height:45px;
        text-align:center;
      }
      &:first-child {
        &:before {
          content:"\f095";
          padding-top:6px;
        }
      }
      &:last-child {
        &:before {
          content:"\f003";
        }
      }
    }
  }
}

#maps {
  width:100%;
  height:600px;
  overflow:hidden;
  iframe {
    -webkit-filter: grayscale(75%);
    filter: grayscale(75%);
  }
}