.navbar-fixed-top {
  top:25px;
  position:absolute;
  left:0;
  right:0;
  z-index:999;
  background:transparent;
  width:100%;
  height:140px;
  border:0;
  -webkit-transition: ease all 0.4s;
  -moz-transition: ease all 0.4s;
  -o-transition: ease all 0.4s;
  transition: ease all 0.4s;
  .navbar-header {
    .brand {
      float:left;
      width:415px;
      margin-top:42px;
      -webkit-transition: ease all 0.25s;
      -moz-transition: ease all 0.25s;
      -o-transition: ease all 0.25s;
      transition: ease all 0.25s;
      position:relative;
      z-index:10;
      .logo {
        max-width:100%;
        display:block;
        position:relative;
        z-index:10;
      }
    }
  }
  .navbar-right {
    margin-top:60px;
    -webkit-transition: ease all 0.25s;
    -moz-transition: ease all 0.25s;
    -o-transition: ease all 0.25s;
    transition: ease all 0.25s;
    li {
      a {
        text-transform:uppercase;
        font-size:22px;
        font-family: $font-standard;
        font-weight:700;
        color:white;
        text-shadow:1px 0 1px rgba(68,111,143,0.5);
        background:transparent;
        position:relative;
        padding:12px 0;
        margin:0 25px;
        -webkit-transition: ease all 0.25s;
        -moz-transition: ease all 0.25s;
        -o-transition: ease all 0.25s;
        transition: ease all 0.25s;
        &:before {
          content:"";
          width:0;
          height:3px;
          border-radius:3px;
          background:$blue;
          position:absolute;
          left:0;
          right:0;
          text-shadow:1px 0 1px rgba(255,255,255,0.1);
          bottom:0;
          margin:0 auto;
          opacity:0;
          -webkit-transition: ease all 0.25s;
          -moz-transition: ease all 0.25s;
          -o-transition: ease all 0.25s;
          transition: ease all 0.25s;
        }
        &:hover,
        &:focus {
          background:transparent;
          color:$blue;
          text-shadow:1px 0 1px rgba(255,255,255,0.1);
          &:before {
            opacity:1;
            width:100%;
          }
        }
      }
      &.active {
        a {
          text-shadow:1px 0 1px rgba(68,111,143,0.5);
          background:transparent;
          color:white;
          &:before {
            content:"";
            width:100%;
            height:3px;
            border-radius:3px;
            background:white;
            position:absolute;
            left:0;
            right:0;
            text-shadow:1px 0 1px rgba(68,111,143,0.5);
            bottom:0;
            opacity:1;
          }
          &:hover,
          &:focus {
            background:transparent;
            color:$blue;
            &:before {
              background:$blue;
            }
          }
        }
      }
    }
  }
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    color:$blue;
  }
  &.stick {
    background:white;
    height:88px;
    top:25px;
    position:fixed;
    box-shadow:0 4px 4px -2px rgba(0, 0, 0, 0.1);
    .navbar-header {
      .brand {
        width:300px;
        margin-top:5px;
      }
    }
    .navbar-right {
      margin-top:21px;
      li {
        a {
          text-shadow:1px 0 1px rgba(68,111,143,0);
          color:$grey;
          &:hover,
          &:focus {
            color:$blue;
          }
        }
        &.active {
          a {
            color:$blue;
            text-shadow:1px 0 1px rgba(255,255,255,0);
            &:before {
              background:$blue;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .menu-toggle {
    display: none;
  }
  .navbar-fixed-top {
    .navbar-right {
      li {
        &.dropdown-large {
          position:static !important;
          .dropdown-toggle {
            i {
              font-size: 16px;
              line-height: 16px;
              vertical-align: 2px;
              margin-left: 3px;
            }
          }
          &.open {
            a {
              background:transparent;
              &.dropdown-toggle {
                background:transparent;
              }
            }
          }
          .dropdown-menu-large {
            width:100%;
            max-width:1476px;
            left:0;
            right:0;
            margin:0 auto;
            padding:30px 30px;
            background:white;
            border:1px solid $grey-light;
            border-top:0;
            border-radius:5px;
            box-shadow:0 5px 6px rgba(0,0,0,.050);
            li {
              width:33.333333%;
              float:left;
              a {
                padding:5px 0;
                height:auto;
                font-size:18px;
                font-weight:700;
                font-family: $font-standard;
                border:0;
                border-bottom:1px solid $grey-light;
                color:$grey;
                text-shadow:0 0 0 hsla(0,0%,100%,0);
                &:before {
                  height:0;
                  width:0;
                }
                &:hover,
                &:focus {
                  background:transparent;
                  color:$blue;
                }
              }
            }
          }
        }
      }
    }
  }
}