#search-overview {
  .text-block {
    h1 {
      margin:0 0 5px 0;
    }
    h1 + h2 {
      margin:0 0 30px 0;
      font-weight:300;
      font-size:18px;
    }
  }
}