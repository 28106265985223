.list {
  margin:0;
  padding:0;
  list-style:none;
  .item {
    .thumbnail {
      padding:0;
      background:$grey-light;
      border: 1px solid #eee;
      width:100%;
      float:left;
      display:block;
      border-radius:7px;
      .album-image {
        float:left;
        width:100%;
        height:280px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        position:relative;
        z-index:1;
        overflow:hidden;
        background:white;
        img {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          width:auto !important;
          height:auto !important;
          max-height:100%;
          max-width:100%;
          margin:0 auto;
        }
      }
      .btn-offerte {
        position:absolute;
        bottom:25px;
        padding-left:30px;
        display:inline-block;
        &:before {
          content:"\f15c";
          font-family: FontAwesome;
          position:absolute;
          left:9px;
          top:4px;
          color:white;
        }
      }
      .extra {
        padding:25px;
        float:left;
        width:100%;
        display:block;
        height:375px;
        min-height:375px;
        position:relative;
        h2 {
          font-size:25px;
        }
        .artnr {
          color:$blue-dark;
          margin:0 0 10px 0;
        }
        .short {
          margin:0;
          display:block;
          min-height:200px;
          height:200px;
          line-height:31px;
          font-size:16px;
          overflow:hidden;
        }
      }
    }
  }
}

.product-image {
  border:1px solid #eee;
}

.card-list {
  margin:0 !important;
  padding:0 !important;
  list-style:none;
  li {
    padding: 0 15px;
    display: block;
    border-radius: 7px;
    .thumbnail {
      padding:0;
      margin-bottom:30px;
      border-radius:0;
      border:1px solid $grey-light;
      height:288px;
      width:100%;
      float:left;
      overflow:hidden;
      z-index:1;
      position:relative;
      .owl-slide {
        width:100%;
        height:288px;
        display:block;
        overflow:hidden;
        z-index:1;
      }
      span {
        position:absolute;
        border:1px solid white;
        border-left:0;
        bottom:15px;
        left:0;
        background:$blue;
        color:white;
        font-size:18px;
        padding:9px 10px 12px 10px;
        z-index:3;
        transition:padding ease-in-out 0.4s;
        small {
          font-weight:300;
        }
        &:after {
          content:"\f002";
          font-family: FontAwesome;
          position:absolute;
          right:15px;
          top:7px;
          font-size:21px;
          color:white;
          opacity:0;
          transition:opacity ease-in-out 0.4s;
          transition-delay: 0.1s;
        }
      }
      &:hover {
        span {
          padding:9px 48px 12px 10px;
          &:after {
            opacity:1;
          }
        }
      }
    }
    &:nth-child(odd) {
      padding-left:0;
    }
    &:nth-child(even) {
      padding-right:0;
    }
  }
}

.list-productspecs {
  margin:15px 0 0 0 !important;
  padding:0 !important;
  list-style:none;
  width:100%;
  display:block;
  float:left;
  li {
    width:100%;
    padding:8px;
    display:block;
    float:left;
    span {
      width:195px;
      float:left;
      display:block;
    }
    span + span {
      width:auto;
      font-weight:700;
    }
    &:nth-child(odd) {
      background:$grey-light;
    }
  }
}
.list-photos {
  margin:15px -15px 0 -15px !important;
  padding:0 !important;
  list-style:none;
  li {
    a {
      margin:0 0 30px 0;
    }
  }
}
#product-detail {
  .text-block {
    .short-info {
      h1 {
        font-size:24px;
        margin:0;
      }
      .product-carousel {
        border:1px solid $grey-light;
        height:250px;
      }
      .list-productspecs {
        li {
          border-bottom:1px solid $grey-light;
          padding:5px 0px;
          span {
            width:150px;
          }
          &:nth-child(odd) {
            background:transparent;
          }
          &:last-child {
            border-bottom:0;
          }
        }
      }
      .col-md-5,
      .col-md-6 {
        padding:0;
      }
    }
    .tab-container {
      float:left;
      width:100%;
      display:block;
      margin:60px 0 0 0;
    }
    .tab-content {
      padding:20px 15px 0 15px;
      h2 {
        font-size:24px;
      }
    }
    .nav-tabs {
      padding:0 !important;
      margin: 0;
    }
  }
}
#products-overview {
  .heading {
    float:left;
    width:100%;
    position:relative;
    display:block;
    margin:0 0 30px 0;
    padding:0 0 0 15px;
  }
  .list {
    .item {
      a.thumbnail {
        .extra {
          height:300px;
          min-height:300px;
        }
      }
    }
  }
}