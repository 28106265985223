#home-catalog {
  .text-block {
    max-width:815px;
  }
  .usp {
    float:left;
    width:100%;
    max-width:815px;
    margin-top:10px;
    ul {
      margin:0;
      padding:0;
      list-style:none;
      li {
        float:left;
        color:$blue;
        font-family: $font-standard;
        font-size:30px;
        margin:0 58px 0 0;
        padding-left:58px;
        position:relative;
        img {
          position:absolute;
          left:0;
          top:0;
        }
        &:last-child {
          margin:0;
        }
        &.service {
          img {
            top:0px;
          }
        }
        &.worldwide-sale {
          img {
            top:-3px;
          }
        }
        &.guarantee {
          img {
            top:-3px;
          }
        }
      }
    }
  }
  .download-buttons {
    margin-top:70px;
    width:100%;
    float:left;
    .btn-secondary {
      margin-right:10px;
    }
    .btn-secondary + .btn-secondary {
      margin-right:0;
    }
  }
  .catalogus {
    position:relative;
    opacity:1;
    a {
      display:block;
      .catalog-text {
        position:relative;
        transform:rotate(1deg);
        font-size:22px;
        font-weight:300;
        font-family: $font-standard;
        display:inline-block;
        margin:0 0 45px 45px;
        text-align:left;
        strong {
          font-weight:400;
          color:$blue;
        }
        &:before {
          content:"";
          position:absolute;
          right:-109px;
          top:39px;
          background:url('/images/arrow-catalogus.png');
          background-repeat:no-repeat;
          width:99px;
          height:83px;
          transform:rotate(12deg);
        }
      }
    }
  }
}

#home-products {
  h2 {
    text-align:center;
    margin:0 0 60px 0;
  }
  h3 {
    font-size:24px;
    margin:20px 0 0 0;
  }
  ul {
    margin:0;
    padding:0;
    list-style:none;
    li {
      color:$blue;
      padding:4px 0;
    }
  }
}

