#contactForm {
  float:left;
  padding:0 0 0 60px;
}
#aanvragenForm {
  #bericht {
    height:180px !important;
  }
}
.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  color: $blue;
  height: 56px;
  padding:8px 20px;
  border-radius: 6px;
  border-color: #eee;
  box-shadow: none;
  background:#f9f9f9;
  font-family: $font-standard;
  &:focus,
  &:active {
    box-shadow: none;
    border-color: $blue;
  }
}
.form-small {
  float:left;
  width:40%;
  padding-right:4px;
}
.form-medium {
  float:left;
  width:60%;
  padding-left:4px;
}
.form-smaller {
  float:left;
  width:25%;
}
.form-more-medium {
  float:left;
  width:75%;
}
.form-more-medium + .form-smaller {
  padding-left:8px;
}
.form-smaller + .form-more-medium {
  padding-left:8px;
}
.form-large {
  float:left;
  width:100%;
}

.form-group {
  margin: 0 0 8px 0;
  float: left;
  width: 100%;
}

textarea.form-control {
  resize: none;
  height: 250px;
  padding:15px 20px;
  border-radius:6px;
}

.alert {
  border-radius: 0;
  font-size: 1.25em;
  ul {
    margin: 0 0 0 19px;
    padding: 0;
    li {
      padding: 2px 0;
    }
  }
}

.has-error {
  position: relative;
  .form-control {
    border-color: $red;
  }
  &:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 14px;
    color: $red;
    font-size: 20px;
  }
}

.alert-danger {
  background: rgba(238, 115, 11, 0.1);
  color: $red;
  border-color: $red;
  border: 0;
  ul {
    margin:0 !important;
    padding:0 0 0 20px !important;
  }
}

#contactForm {
  .form-control::-webkit-input-placeholder {
    color: #abacad;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
  .form-control::-moz-placeholder {
    color: #abacad;
  }
  .form-control:focus::-moz-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
  .form-control:-ms-input-placeholder {
    color: #abacad;
  }
  .form-control:focus:-ms-input-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
}
.form-content {
  h2 {
    margin:0 0 30px 0;
    padding:0;
    text-align:center;
  }
}


span.required {
  font-size:13px;
  color:#aaa;
}
#searchForm {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  z-index:901;
  margin-top:60px;
  .form-control {
    width:600px;
    padding-left:55px;
    font-size:25px;
  }
  .search {
    position:relative;
    &:before {
      content:"\f002";
      font-family: FontAwesome;
      position:absolute;
      z-index:111;
      left:19px;
      top:8px;
      color:$blue;
      font-size:26px;
    }
  }
  .form-control::-webkit-input-placeholder {
    color: $blue;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
  .form-control::-moz-placeholder {
    color: $blue;
  }
  .form-control:focus::-moz-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
  .form-control:-ms-input-placeholder {
    color: $blue;
  }
  .form-control:focus:-ms-input-placeholder {
    color: $blue;
    transition:color 0.25s ease;
  }
}
#offerteForm {
  h2 {
    margin:0 0 20px 0;
  }
  .form-control {
    background:white;
  }
  .offer-request {
    margin:0 0 15px 0;
    display:block;
    font-size:20px;
    font-family: $font-standard;
  }
}

