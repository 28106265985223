@media only screen and (max-width : 991px) {
  #slider-container {
    height:320px;
    #slider {
      min-height:320px;
      .owl-slide {
        height:320px !important;
      }
    }
  }
  header {
    #header-image {
      height:320px !important;
    }
  }
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        margin-top:5px;
        width:235px;
      }
    }
    .navbar-right {
      margin-top:24px;
      li {
        &:first-child {
          display:none;
        }
        a {
          font-size:17px;
          margin:0 12px;
        }
      }
    }
    &.stick {
      .navbar-header {
        .brand{
          width:235px;
          margin-top:0px;
        }
      }
    }
  }
  section {
    &.padded {
      padding:60px 35px;
    }
  }
  #home-catalog {
    padding:60px 35px;
    .text-block {
      padding-right:0;
    }
    .usp ul li {
      font-size:22px;
      &.service {
        padding-left:45px;
        img {
          width:36px;
          height:30px;
        }
      }
    }
  }
  .sidebar {
    background:none;
    width:100%;
    margin:0 0 30px 0;
    h4 {
      margin:0 0 15px 0;
    }
    #sidebar-list {
      width:100%;
    }
  }
  #home-products {
    padding:60px 35px;
  }
  #news-overview-cards {
    padding:0px 35px;
  }
  #contact-info {
    .col-lg-6 {
      width:100%;
      margin-left:0;
      max-width:100%;
    }
  }
}

