footer {
  background:$blue;
  width:100%;
  padding:40px 0 0 0;
  float:left;
  .footer-logo {
    float:left;
    margin:0;
    padding:0;
    display:block;
    position:relative;
    width:300px;
    img {
      -webkit-filter: grayscale(60%);
      filter: grayscale(60%);
      max-width:100%;
      display:block;
    }
  }
  .footer-address {
    float:right;
    position:relative;
    margin:0 0 50px 0;
    ul {
      margin:10px 70px 0 0;
      padding:0;
      list-style: none;
      float:left;
      li {
        display:block;
        color:white;
        padding:0;
        line-height:30px;
        font-weight:300;
        a {
          color:white;
          position:relative;
          padding-left:28px;
          font-weight:300;
        }
        &:first-child {
          a {
            &:before {
              content:"\f095";
              font-family: FontAwesome;
              position:absolute;
              left:0;
              top:-2px;
            }
          }
        }
        &:last-child {
          a {
            &:before {
              content:"\f003";
              font-family: FontAwesome;
              position:absolute;
              left:0;
              top:-4px;
            }
          }
        }
      }
    }
    ul + ul {
      margin-right:0;
    }
  }
  .bottom {
    background:$blue;
    border-top:1px solid $blue-dark;
    text-align:center;
    float:left;
    position:relative;
    padding:5px 0;
    width:100%;
    display:block;
    a {
      color:white;
      font-size:14px;
      opacity:0.6;
      text-transform: lowercase;
      &:hover,
      &:focus {
        opacity:1;
      }
    }
  }
}