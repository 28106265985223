#news-overview-intro {
  p {
    margin:0;
  }
}
#news-overview-cards {
  float:left;
  width:100%;
  margin:0 0 80px 0;
  .thumbnail {
    float:left;
    padding:0;
    margin:0 0 30px 0;
    width:100%;
    border:0;
    .album {
      float:left;
      width:100%;
      padding:0;
      display: block;
      position:relative;
      z-index:10;
      border:1px solid #eee;
      background:$grey-light;
      border-radius:7px;
      transition:ease all 0.25s;
      .album-image {
        float:left;
        width:100%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        position:relative;
        z-index:1;
        overflow:hidden;
        img {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
        }
        &:before {
          content:"\f061";
          font-family: FontAwesome;
          position:absolute;
          right:25px;
          bottom:20px;
          color:$blue;
          font-size:32px;
          transition:ease all 0.25s;
          z-index:100;
        }
        &:after {
          content:"";
          position:absolute;
          bottom:-100%;
          width:100%;
          height:100%;
          transition:ease all 0.25s;
          background: -moz-linear-gradient(top, rgba(125,185,232,0) 0%, rgba(32,111,146,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(125,185,232,0) 0%,rgba(32,111,146,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(125,185,232,0) 0%,rgba(32,111,146,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#206f92',GradientType=0 ); /* IE6-9 */
          opacity:0;
        }
      }
      .album-desc {
        position:relative;
        z-index:10;
        padding:25px;
        float:left;
        width:100%;
        h2 {
          margin-top: 0;
          margin-bottom: 4px;
          transition:ease all 0.3s;
        }
        p {
          margin:0;
          padding:0;
          color:$grey;
          font-family: $font-standard;
          font-weight:300;
        }
      }
      &:hover,
      &:focus {
        .album-image {
          &:before {
            color:white;
          }
          &:after {
            bottom:0;
            opacity:0.75;
          }
        }
      }
    }
  }
}
#news-item {
  h1 {
    margin:0 0 10px 0;
  }
  h4 {
    color:$grey;
    margin:4px 0 30px 0;
  }
}