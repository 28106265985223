.btn-primary,
.btn-secondary {
  border:0;
  padding:16px 30px;
  position:relative;
  color:white;
  border-radius:6px;
  font-weight:700;
  font-size:22px;
  -webkit-transition: background-color ease 0.25s;
  -moz-transition: background-color ease 0.25s;
  -o-transition: background-color ease 0.25s;
  transition: background-color ease 0.25s;
}
.btn-primary {
  color:white;
  background:$blue;
  border-bottom:3px solid $blue-dark;
  &:hover,
  &:focus {
    background:$blue-dark;
    border-color:#25455c;
  }
}
.btn-secondary {
  color:$green-dark;
  background:$green;
  border-bottom:3px solid $green-dark;
  &:hover,
  &:focus {
    color:white;
    background:$green-dark;
    border-color:#777113;
  }
}

.btn-view,
.btn-download {
  padding-left:75px;
  img {
    position:absolute;
    z-index:2;
  }
  &:before {
    content:"";
    width:46px;
    height:46px;
    position:absolute;
    top:9px;
    left:9px;
    background:white;
    border-radius:50%;
    z-index:0;
  }
}
.btn-arrow {
  padding-right:66px;
  &:after {
    content:"\f1d9";
    font-family: FontAwesome;
    position:absolute;
    right:30px;
    top:17px;
    font-size:22px;
    font-weight:300;
    transition:ease all 0.25s;
  }
  &:hover,
  &:focus {
    &:after {
      right:26px;
      top:14px;
    }
  }
}
.btn-back {
  position:relative;
  margin-bottom:10px;
  background:transparent;
  border:0;
  display:inline-block;
  padding:0 0 0 21px;
  color:$blue;
  font-weight:300;
  font-size:16px;
  &:before {
    content:"\f060";
    font-family: FontAwesome;
    left:0;
    top:1px;
    font-size:13px;
    color:$blue;
    position:absolute;
  }
  &:hover,
  &:focus {
    background:transparent;
    color:$green;
    &:before {
      color:$green;
    }
  }
}
.btn-view {
  img {
    top:19px;
    left:20px;
  }
}
.btn-download {
  img {
    top:17px;
    left:21px;
  }
}
.btn-offerte {
  background:$blue;
  color:white;
  padding:5px 8px;
  border-radius:5px;
  &:hover,
  &:focus {
    background:$blue-dark;
    color:white;
  }
}