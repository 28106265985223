.btn-primary,
.btn-secondary {
  border: 0;
  padding: 16px 30px;
  position: relative;
  color: white;
  border-radius: 6px;
  font-weight: 700;
  font-size: 22px;
  transition: background-color ease 0.25s; }

.btn-primary {
  color: white;
  background: #206f92;
  border-bottom: 3px solid #325874; }
  .btn-primary:hover, .btn-primary:focus {
    background: #325874;
    border-color: #25455c; }

.btn-secondary {
  color: #978f1a;
  background: #ded662;
  border-bottom: 3px solid #978f1a; }
  .btn-secondary:hover, .btn-secondary:focus {
    color: white;
    background: #978f1a;
    border-color: #777113; }

.btn-view,
.btn-download {
  padding-left: 75px; }
  .btn-view img,
  .btn-download img {
    position: absolute;
    z-index: 2; }
  .btn-view:before,
  .btn-download:before {
    content: "";
    width: 46px;
    height: 46px;
    position: absolute;
    top: 9px;
    left: 9px;
    background: white;
    border-radius: 50%;
    z-index: 0; }

.btn-arrow {
  padding-right: 66px; }
  .btn-arrow:after {
    content: "\f1d9";
    font-family: FontAwesome;
    position: absolute;
    right: 30px;
    top: 17px;
    font-size: 22px;
    font-weight: 300;
    transition: ease all 0.25s; }
  .btn-arrow:hover:after, .btn-arrow:focus:after {
    right: 26px;
    top: 14px; }

.btn-back {
  position: relative;
  margin-bottom: 10px;
  background: transparent;
  border: 0;
  display: inline-block;
  padding: 0 0 0 21px;
  color: #206f92;
  font-weight: 300;
  font-size: 16px; }
  .btn-back:before {
    content: "\f060";
    font-family: FontAwesome;
    left: 0;
    top: 1px;
    font-size: 13px;
    color: #206f92;
    position: absolute; }
  .btn-back:hover, .btn-back:focus {
    background: transparent;
    color: #ded662; }
    .btn-back:hover:before, .btn-back:focus:before {
      color: #ded662; }

.btn-view img {
  top: 19px;
  left: 20px; }

.btn-download img {
  top: 17px;
  left: 21px; }

.btn-offerte {
  background: #206f92;
  color: white;
  padding: 5px 8px;
  border-radius: 5px; }
  .btn-offerte:hover, .btn-offerte:focus {
    background: #325874;
    color: white; }

#contactForm {
  float: left;
  padding: 0 0 0 60px; }

#aanvragenForm #bericht {
  height: 180px !important; }

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  color: #206f92;
  height: 56px;
  padding: 8px 20px;
  border-radius: 6px;
  border-color: #eee;
  box-shadow: none;
  background: #f9f9f9;
  font-family: "Source Sans Pro", sans-serif; }
  .form-control:focus, .form-control:active {
    box-shadow: none;
    border-color: #206f92; }

.form-small {
  float: left;
  width: 40%;
  padding-right: 4px; }

.form-medium {
  float: left;
  width: 60%;
  padding-left: 4px; }

.form-smaller {
  float: left;
  width: 25%; }

.form-more-medium {
  float: left;
  width: 75%; }

.form-more-medium + .form-smaller {
  padding-left: 8px; }

.form-smaller + .form-more-medium {
  padding-left: 8px; }

.form-large {
  float: left;
  width: 100%; }

.form-group {
  margin: 0 0 8px 0;
  float: left;
  width: 100%; }

textarea.form-control {
  resize: none;
  height: 250px;
  padding: 15px 20px;
  border-radius: 6px; }

.alert {
  border-radius: 0;
  font-size: 1.25em; }
  .alert ul {
    margin: 0 0 0 19px;
    padding: 0; }
    .alert ul li {
      padding: 2px 0; }

.has-error {
  position: relative; }
  .has-error .form-control {
    border-color: #FF3100; }
  .has-error:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 14px;
    color: #FF3100;
    font-size: 20px; }

.alert-danger {
  background: rgba(238, 115, 11, 0.1);
  color: #FF3100;
  border-color: #FF3100;
  border: 0; }
  .alert-danger ul {
    margin: 0 !important;
    padding: 0 0 0 20px !important; }

#contactForm .form-control::-webkit-input-placeholder {
  color: #abacad; }

#contactForm .form-control:focus::-webkit-input-placeholder {
  color: #206f92;
  transition: color 0.25s ease; }

#contactForm .form-control::-moz-placeholder {
  color: #abacad; }

#contactForm .form-control:focus::-moz-placeholder {
  color: #206f92;
  transition: color 0.25s ease; }

#contactForm .form-control:-ms-input-placeholder {
  color: #abacad; }

#contactForm .form-control:focus:-ms-input-placeholder {
  color: #206f92;
  transition: color 0.25s ease; }

.form-content h2 {
  margin: 0 0 30px 0;
  padding: 0;
  text-align: center; }

span.required {
  font-size: 13px;
  color: #aaa; }

#searchForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 901;
  margin-top: 60px; }
  #searchForm .form-control {
    width: 600px;
    padding-left: 55px;
    font-size: 25px; }
  #searchForm .search {
    position: relative; }
    #searchForm .search:before {
      content: "\f002";
      font-family: FontAwesome;
      position: absolute;
      z-index: 111;
      left: 19px;
      top: 8px;
      color: #206f92;
      font-size: 26px; }
  #searchForm .form-control::-webkit-input-placeholder {
    color: #206f92; }
  #searchForm .form-control:focus::-webkit-input-placeholder {
    color: #206f92;
    transition: color 0.25s ease; }
  #searchForm .form-control::-moz-placeholder {
    color: #206f92; }
  #searchForm .form-control:focus::-moz-placeholder {
    color: #206f92;
    transition: color 0.25s ease; }
  #searchForm .form-control:-ms-input-placeholder {
    color: #206f92; }
  #searchForm .form-control:focus:-ms-input-placeholder {
    color: #206f92;
    transition: color 0.25s ease; }

#offerteForm h2 {
  margin: 0 0 20px 0; }

#offerteForm .form-control {
  background: white; }

#offerteForm .offer-request {
  margin: 0 0 15px 0;
  display: block;
  font-size: 20px;
  font-family: "Source Sans Pro", sans-serif; }

#slider-container {
  width: 100%;
  height: 570px;
  overflow: hidden;
  position: relative;
  float: left;
  display: block; }
  #slider-container #slider {
    width: 100%;
    height: 100%;
    min-height: 570px;
    position: relative;
    overflow: hidden; }
    #slider-container #slider div:not(.owl-controls) {
      height: 100%;
      position: relative; }
    #slider-container #slider .owl-item {
      height: 100%;
      position: relative; }
    #slider-container #slider .owl-slide {
      z-index: 100;
      position: relative; }

.overlay {
  position: relative; }
  .overlay:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3; }

html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%; }

html {
  height: 100%; }

body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #545454;
  font-size: 100%;
  padding: 0;
  margin: 0; }

a {
  outline: 0;
  color: #206f92;
  font-weight: 400; }
  a:hover, a:focus {
    color: #325874;
    text-decoration: none;
    outline: 0; }

h1, h2, h3, h4, h5, h6 {
  color: #206f92;
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700; }

h1 {
  font-size: 52px; }

h2 {
  font-size: 32px; }

p {
  font-size: 18px;
  line-height: 36px;
  font-family: "Source Sans Pro", sans-serif;
  margin: 0 0 30px 0; }

.container-fluid {
  width: 100%;
  max-width: 1476px; }

section {
  float: left;
  width: 100%;
  position: relative; }
  section.padded {
    padding: 120px 0; }
  section.grey {
    background: #f5f5f5; }
  section.pattern {
    background: #f5f5f5 url(/images/pattern-pegasus.png) no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 80px 0; }
  section .text-block {
    width: 100%;
    float: left; }
    section .text-block h1 {
      margin: 0 0 30px 0; }
    section .text-block h2, section .text-block h3, section .text-block h4, section .text-block h5, section .text-block h6 {
      margin: 0 0 4px 0; }
    section .text-block p, section .text-block ul, section .text-block ol, section .text-block li, section .text-block blockquote {
      font-size: 18px;
      line-height: 36px;
      font-family: "Source Sans Pro", sans-serif; }
    section .text-block p {
      margin: 0 0 30px 0; }
    section .text-block ul, section .text-block ol {
      margin: 30px 0 30px;
      padding: 0 0 0 20px; }
    section .text-block ul > li > ul {
      margin: 0; }

.navbar-top {
  width: 100%;
  background: #206f92;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  font-weight: 300;
  line-height: 25px;
  vertical-align: middle;
  font-size: 16px;
  z-index: 1000; }
  .navbar-top .contact {
    float: left; }
  .navbar-top .language {
    float: right; }
    .navbar-top .language .navbar-nav {
      margin: 0;
      padding: 0; }
      .navbar-top .language .navbar-nav li {
        padding: 0;
        margin: 0; }
        .navbar-top .language .navbar-nav li a {
          padding: 0 15px 0 0;
          margin: 0;
          font-size: 16px;
          font-family: "Source Sans Pro", sans-serif;
          color: white;
          font-weight: 300;
          line-height: 25px;
          vertical-align: middle; }
          .navbar-top .language .navbar-nav li a i {
            font-size: 11px;
            position: absolute;
            right: 0;
            top: 5px; }
          .navbar-top .language .navbar-nav li a:hover, .navbar-top .language .navbar-nav li a:focus {
            background: transparent;
            color: #ded662; }
        .navbar-top .language .navbar-nav li .dropdown-menu {
          min-width: 50px;
          background: #206f92;
          box-shadow: 0 6px 12px transparent;
          border-radius: 0;
          right: 0;
          left: auto; }
          .navbar-top .language .navbar-nav li .dropdown-menu li a {
            padding: 3px 8px;
            text-align: left;
            color: white; }
            .navbar-top .language .navbar-nav li .dropdown-menu li a:hover, .navbar-top .language .navbar-nav li .dropdown-menu li a:focus {
              color: #ded662; }
  .navbar-top a {
    color: white; }
    .navbar-top a:hover, .navbar-top a:focus {
      text-decoration: underline; }

.navbar-fixed-top {
  top: 25px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  background: transparent;
  width: 100%;
  height: 140px;
  border: 0;
  transition: ease all 0.4s; }
  .navbar-fixed-top .navbar-header .brand {
    float: left;
    width: 415px;
    margin-top: 42px;
    transition: ease all 0.25s;
    position: relative;
    z-index: 10; }
    .navbar-fixed-top .navbar-header .brand .logo {
      max-width: 100%;
      display: block;
      position: relative;
      z-index: 10; }
  .navbar-fixed-top .navbar-right {
    margin-top: 60px;
    transition: ease all 0.25s; }
    .navbar-fixed-top .navbar-right li a {
      text-transform: uppercase;
      font-size: 22px;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 700;
      color: white;
      text-shadow: 1px 0 1px rgba(68, 111, 143, 0.5);
      background: transparent;
      position: relative;
      padding: 12px 0;
      margin: 0 25px;
      transition: ease all 0.25s; }
      .navbar-fixed-top .navbar-right li a:before {
        content: "";
        width: 0;
        height: 3px;
        border-radius: 3px;
        background: #206f92;
        position: absolute;
        left: 0;
        right: 0;
        text-shadow: 1px 0 1px rgba(255, 255, 255, 0.1);
        bottom: 0;
        margin: 0 auto;
        opacity: 0;
        transition: ease all 0.25s; }
      .navbar-fixed-top .navbar-right li a:hover, .navbar-fixed-top .navbar-right li a:focus {
        background: transparent;
        color: #206f92;
        text-shadow: 1px 0 1px rgba(255, 255, 255, 0.1); }
        .navbar-fixed-top .navbar-right li a:hover:before, .navbar-fixed-top .navbar-right li a:focus:before {
          opacity: 1;
          width: 100%; }
    .navbar-fixed-top .navbar-right li.active a {
      text-shadow: 1px 0 1px rgba(68, 111, 143, 0.5);
      background: transparent;
      color: white; }
      .navbar-fixed-top .navbar-right li.active a:before {
        content: "";
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background: white;
        position: absolute;
        left: 0;
        right: 0;
        text-shadow: 1px 0 1px rgba(68, 111, 143, 0.5);
        bottom: 0;
        opacity: 1; }
      .navbar-fixed-top .navbar-right li.active a:hover, .navbar-fixed-top .navbar-right li.active a:focus {
        background: transparent;
        color: #206f92; }
        .navbar-fixed-top .navbar-right li.active a:hover:before, .navbar-fixed-top .navbar-right li.active a:focus:before {
          background: #206f92; }
  .navbar-fixed-top .navbar-nav > .open > a,
  .navbar-fixed-top .navbar-nav > .open > a:focus,
  .navbar-fixed-top .navbar-nav > .open > a:hover {
    color: #206f92; }
  .navbar-fixed-top.stick {
    background: white;
    height: 88px;
    top: 25px;
    position: fixed;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1); }
    .navbar-fixed-top.stick .navbar-header .brand {
      width: 300px;
      margin-top: 5px; }
    .navbar-fixed-top.stick .navbar-right {
      margin-top: 21px; }
      .navbar-fixed-top.stick .navbar-right li a {
        text-shadow: 1px 0 1px rgba(68, 111, 143, 0);
        color: #545454; }
        .navbar-fixed-top.stick .navbar-right li a:hover, .navbar-fixed-top.stick .navbar-right li a:focus {
          color: #206f92; }
      .navbar-fixed-top.stick .navbar-right li.active a {
        color: #206f92;
        text-shadow: 1px 0 1px rgba(255, 255, 255, 0); }
        .navbar-fixed-top.stick .navbar-right li.active a:before {
          background: #206f92; }

@media only screen and (min-width: 768px) {
  .menu-toggle {
    display: none; }
  .navbar-fixed-top .navbar-right li.dropdown-large {
    position: static !important; }
    .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-toggle i {
      font-size: 16px;
      line-height: 16px;
      vertical-align: 2px;
      margin-left: 3px; }
    .navbar-fixed-top .navbar-right li.dropdown-large.open a {
      background: transparent; }
      .navbar-fixed-top .navbar-right li.dropdown-large.open a.dropdown-toggle {
        background: transparent; }
    .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large {
      width: 100%;
      max-width: 1476px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 30px 30px;
      background: white;
      border: 1px solid #f5f5f5;
      border-top: 0;
      border-radius: 5px;
      box-shadow: 0 5px 6px rgba(0, 0, 0, 0.05); }
      .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large li {
        width: 33.333333%;
        float: left; }
        .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large li a {
          padding: 5px 0;
          height: auto;
          font-size: 18px;
          font-weight: 700;
          font-family: "Source Sans Pro", sans-serif;
          border: 0;
          border-bottom: 1px solid #f5f5f5;
          color: #545454;
          text-shadow: 0 0 0 rgba(255, 255, 255, 0); }
          .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large li a:before {
            height: 0;
            width: 0; }
          .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large li a:hover, .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large li a:focus {
            background: transparent;
            color: #206f92; } }

.sidebar {
  padding: 0;
  margin: 0;
  border: 0;
  line-height: 1;
  width: 100%;
  max-width: 320px;
  background: white;
  zoom: 1; }
  .sidebar a {
    display: block;
    padding: 15px 20px;
    color: #206f92;
    font-size: 18px;
    text-decoration: none; }
  .sidebar ul.products-list {
    position: relative;
    z-index: 597;
    float: left;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
    .sidebar ul.products-list li {
      list-style: none;
      margin: 0;
      padding: 0;
      float: none;
      min-height: 1px;
      line-height: 1em;
      vertical-align: middle;
      position: relative;
      background: #f5f5f5; }
      .sidebar ul.products-list li a {
        border-right: 4px solid #206f92;
        color: #206f92; }
        .sidebar ul.products-list li a:hover {
          background: #206f92;
          color: white; }
      .sidebar ul.products-list li:hover,
      .sidebar ul.products-list li .hover {
        position: relative;
        z-index: 599;
        cursor: default;
        color: #ffffff; }
        .sidebar ul.products-list li:hover ul,
        .sidebar ul.products-list li .hover ul {
          visibility: visible; }
        .sidebar ul.products-list li:hover a,
        .sidebar ul.products-list li .hover a {
          background: #206f92;
          color: white; }
      .sidebar ul.products-list li.active a {
        background: #206f92;
        color: white; }
    .sidebar ul.products-list ul {
      list-style: none;
      margin: 0;
      padding: 0;
      visibility: hidden;
      position: absolute;
      z-index: 598;
      width: 100%;
      top: 0px;
      left: 99%;
      border: 1px solid #206f92;
      border-radius: 0 3px 3px 0; }
      .sidebar ul.products-list ul a {
        font-size: 18px;
        color: #ffffff; }
        .sidebar ul.products-list ul a:hover {
          color: #ffffff; }
      .sidebar ul.products-list ul li {
        float: none;
        font-weight: normal; }
        .sidebar ul.products-list ul li a {
          border-right: 0px solid #206f92; }
        .sidebar ul.products-list ul li:hover a {
          background: #f5f5f5;
          color: #206f92; }
      .sidebar ul.products-list ul ul {
        top: -2px;
        right: 0; }
  .sidebar:before {
    content: '';
    display: block; }
  .sidebar:after {
    content: '';
    display: table;
    clear: both; }

.sidebar ul li.has-sub > a:after {
  content: '+';
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -9px; }

.sidebar ul li.has-sub ul li.has-sub > a:after {
  content: ''; }

.search-block {
  display: block;
  overflow: auto;
  margin: 0 0 30px 0;
  position: relative;
  padding: 0px;
  background: white; }
  .search-block h4 {
    font-weight: 400;
    font-size: 21px;
    margin: 0 0 10px 0; }
  .search-block .navbar-form {
    padding: 0;
    margin: 0; }
    .search-block .navbar-form .form-control {
      width: 100%;
      background: white;
      position: relative;
      z-index: 0; }
    .search-block .navbar-form .form-group.search {
      position: relative; }
  .search-block #btn-search-large {
    width: 54px;
    height: 54px;
    border: 0;
    border-radius: 5px;
    background: #206f92;
    color: white;
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 20;
    transition: background ease-in-out 0.25s; }
    .search-block #btn-search-large i {
      font-size: 21px;
      color: white; }
    .search-block #btn-search-large:hover, .search-block #btn-search-large:focus {
      background: #325874;
      color: white; }

/*
header {
  margin-top:25px;
  position:relative;
  z-index:0;
  background:black;
  #header-image {
    opacity:0.9;
    width:100%;
    height:570px;
    overflow:hidden;
    position:relative;
  }
}*/
footer {
  background: #206f92;
  width: 100%;
  padding: 40px 0 0 0;
  float: left; }
  footer .footer-logo {
    float: left;
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    width: 300px; }
    footer .footer-logo img {
      filter: grayscale(60%);
      max-width: 100%;
      display: block; }
  footer .footer-address {
    float: right;
    position: relative;
    margin: 0 0 50px 0; }
    footer .footer-address ul {
      margin: 10px 70px 0 0;
      padding: 0;
      list-style: none;
      float: left; }
      footer .footer-address ul li {
        display: block;
        color: white;
        padding: 0;
        line-height: 30px;
        font-weight: 300; }
        footer .footer-address ul li a {
          color: white;
          position: relative;
          padding-left: 28px;
          font-weight: 300; }
        footer .footer-address ul li:first-child a:before {
          content: "\f095";
          font-family: FontAwesome;
          position: absolute;
          left: 0;
          top: -2px; }
        footer .footer-address ul li:last-child a:before {
          content: "\f003";
          font-family: FontAwesome;
          position: absolute;
          left: 0;
          top: -4px; }
    footer .footer-address ul + ul {
      margin-right: 0; }
  footer .bottom {
    background: #206f92;
    border-top: 1px solid #325874;
    text-align: center;
    float: left;
    position: relative;
    padding: 5px 0;
    width: 100%;
    display: block; }
    footer .bottom a {
      color: white;
      font-size: 14px;
      opacity: 0.6;
      text-transform: lowercase; }
      footer .bottom a:hover, footer .bottom a:focus {
        opacity: 1; }

#search-overview .text-block h1 {
  margin: 0 0 5px 0; }

#search-overview .text-block h1 + h2 {
  margin: 0 0 30px 0;
  font-weight: 300;
  font-size: 18px; }

#home-catalog .text-block {
  max-width: 815px; }

#home-catalog .usp {
  float: left;
  width: 100%;
  max-width: 815px;
  margin-top: 10px; }
  #home-catalog .usp ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    #home-catalog .usp ul li {
      float: left;
      color: #206f92;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 30px;
      margin: 0 58px 0 0;
      padding-left: 58px;
      position: relative; }
      #home-catalog .usp ul li img {
        position: absolute;
        left: 0;
        top: 0; }
      #home-catalog .usp ul li:last-child {
        margin: 0; }
      #home-catalog .usp ul li.service img {
        top: 0px; }
      #home-catalog .usp ul li.worldwide-sale img {
        top: -3px; }
      #home-catalog .usp ul li.guarantee img {
        top: -3px; }

#home-catalog .download-buttons {
  margin-top: 70px;
  width: 100%;
  float: left; }
  #home-catalog .download-buttons .btn-secondary {
    margin-right: 10px; }
  #home-catalog .download-buttons .btn-secondary + .btn-secondary {
    margin-right: 0; }

#home-catalog .catalogus {
  position: relative;
  opacity: 1; }
  #home-catalog .catalogus a {
    display: block; }
    #home-catalog .catalogus a .catalog-text {
      position: relative;
      transform: rotate(1deg);
      font-size: 22px;
      font-weight: 300;
      font-family: "Source Sans Pro", sans-serif;
      display: inline-block;
      margin: 0 0 45px 45px;
      text-align: left; }
      #home-catalog .catalogus a .catalog-text strong {
        font-weight: 400;
        color: #206f92; }
      #home-catalog .catalogus a .catalog-text:before {
        content: "";
        position: absolute;
        right: -109px;
        top: 39px;
        background: url("/images/arrow-catalogus.png");
        background-repeat: no-repeat;
        width: 99px;
        height: 83px;
        transform: rotate(12deg); }

#home-products h2 {
  text-align: center;
  margin: 0 0 60px 0; }

#home-products h3 {
  font-size: 24px;
  margin: 20px 0 0 0; }

#home-products ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  #home-products ul li {
    color: #206f92;
    padding: 4px 0; }

#news-overview-intro p {
  margin: 0; }

#news-overview-cards {
  float: left;
  width: 100%;
  margin: 0 0 80px 0; }
  #news-overview-cards .thumbnail {
    float: left;
    padding: 0;
    margin: 0 0 30px 0;
    width: 100%;
    border: 0; }
    #news-overview-cards .thumbnail .album {
      float: left;
      width: 100%;
      padding: 0;
      display: block;
      position: relative;
      z-index: 10;
      border: 1px solid #eee;
      background: #f5f5f5;
      border-radius: 7px;
      transition: ease all 0.25s; }
      #news-overview-cards .thumbnail .album .album-image {
        float: left;
        width: 100%;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        position: relative;
        z-index: 1;
        overflow: hidden; }
        #news-overview-cards .thumbnail .album .album-image img {
          border-top-left-radius: 7px;
          border-top-right-radius: 7px; }
        #news-overview-cards .thumbnail .album .album-image:before {
          content: "\f061";
          font-family: FontAwesome;
          position: absolute;
          right: 25px;
          bottom: 20px;
          color: #206f92;
          font-size: 32px;
          transition: ease all 0.25s;
          z-index: 100; }
        #news-overview-cards .thumbnail .album .album-image:after {
          content: "";
          position: absolute;
          bottom: -100%;
          width: 100%;
          height: 100%;
          transition: ease all 0.25s;
          /* FF3.6-15 */
          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, #206f92 100%);
          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#206f92',GradientType=0 );
          /* IE6-9 */
          opacity: 0; }
      #news-overview-cards .thumbnail .album .album-desc {
        position: relative;
        z-index: 10;
        padding: 25px;
        float: left;
        width: 100%; }
        #news-overview-cards .thumbnail .album .album-desc h2 {
          margin-top: 0;
          margin-bottom: 4px;
          transition: ease all 0.3s; }
        #news-overview-cards .thumbnail .album .album-desc p {
          margin: 0;
          padding: 0;
          color: #545454;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 300; }
      #news-overview-cards .thumbnail .album:hover .album-image:before, #news-overview-cards .thumbnail .album:focus .album-image:before {
        color: white; }
      #news-overview-cards .thumbnail .album:hover .album-image:after, #news-overview-cards .thumbnail .album:focus .album-image:after {
        bottom: 0;
        opacity: 0.75; }

#news-item h1 {
  margin: 0 0 10px 0; }

#news-item h4 {
  color: #545454;
  margin: 4px 0 30px 0; }

.list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list .item .thumbnail {
    padding: 0;
    background: #f5f5f5;
    border: 1px solid #eee;
    width: 100%;
    float: left;
    display: block;
    border-radius: 7px; }
    .list .item .thumbnail .album-image {
      float: left;
      width: 100%;
      height: 280px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      position: relative;
      z-index: 1;
      overflow: hidden;
      background: white; }
      .list .item .thumbnail .album-image img {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        width: auto !important;
        height: auto !important;
        max-height: 100%;
        max-width: 100%;
        margin: 0 auto; }
    .list .item .thumbnail .btn-offerte {
      position: absolute;
      bottom: 25px;
      padding-left: 30px;
      display: inline-block; }
      .list .item .thumbnail .btn-offerte:before {
        content: "\f15c";
        font-family: FontAwesome;
        position: absolute;
        left: 9px;
        top: 4px;
        color: white; }
    .list .item .thumbnail .extra {
      padding: 25px;
      float: left;
      width: 100%;
      display: block;
      height: 375px;
      min-height: 375px;
      position: relative; }
      .list .item .thumbnail .extra h2 {
        font-size: 25px; }
      .list .item .thumbnail .extra .artnr {
        color: #325874;
        margin: 0 0 10px 0; }
      .list .item .thumbnail .extra .short {
        margin: 0;
        display: block;
        min-height: 200px;
        height: 200px;
        line-height: 31px;
        font-size: 16px;
        overflow: hidden; }

.product-image {
  border: 1px solid #eee; }

.card-list {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none; }
  .card-list li {
    padding: 0 15px;
    display: block;
    border-radius: 7px; }
    .card-list li .thumbnail {
      padding: 0;
      margin-bottom: 30px;
      border-radius: 0;
      border: 1px solid #f5f5f5;
      height: 288px;
      width: 100%;
      float: left;
      overflow: hidden;
      z-index: 1;
      position: relative; }
      .card-list li .thumbnail .owl-slide {
        width: 100%;
        height: 288px;
        display: block;
        overflow: hidden;
        z-index: 1; }
      .card-list li .thumbnail span {
        position: absolute;
        border: 1px solid white;
        border-left: 0;
        bottom: 15px;
        left: 0;
        background: #206f92;
        color: white;
        font-size: 18px;
        padding: 9px 10px 12px 10px;
        z-index: 3;
        transition: padding ease-in-out 0.4s; }
        .card-list li .thumbnail span small {
          font-weight: 300; }
        .card-list li .thumbnail span:after {
          content: "\f002";
          font-family: FontAwesome;
          position: absolute;
          right: 15px;
          top: 7px;
          font-size: 21px;
          color: white;
          opacity: 0;
          transition: opacity ease-in-out 0.4s;
          transition-delay: 0.1s; }
      .card-list li .thumbnail:hover span {
        padding: 9px 48px 12px 10px; }
        .card-list li .thumbnail:hover span:after {
          opacity: 1; }
    .card-list li:nth-child(odd) {
      padding-left: 0; }
    .card-list li:nth-child(even) {
      padding-right: 0; }

.list-productspecs {
  margin: 15px 0 0 0 !important;
  padding: 0 !important;
  list-style: none;
  width: 100%;
  display: block;
  float: left; }
  .list-productspecs li {
    width: 100%;
    padding: 8px;
    display: block;
    float: left; }
    .list-productspecs li span {
      width: 195px;
      float: left;
      display: block; }
    .list-productspecs li span + span {
      width: auto;
      font-weight: 700; }
    .list-productspecs li:nth-child(odd) {
      background: #f5f5f5; }

.list-photos {
  margin: 15px -15px 0 -15px !important;
  padding: 0 !important;
  list-style: none; }
  .list-photos li a {
    margin: 0 0 30px 0; }

#product-detail .text-block .short-info h1 {
  font-size: 24px;
  margin: 0; }

#product-detail .text-block .short-info .product-carousel {
  border: 1px solid #f5f5f5;
  height: 250px; }

#product-detail .text-block .short-info .list-productspecs li {
  border-bottom: 1px solid #f5f5f5;
  padding: 5px 0px; }
  #product-detail .text-block .short-info .list-productspecs li span {
    width: 150px; }
  #product-detail .text-block .short-info .list-productspecs li:nth-child(odd) {
    background: transparent; }
  #product-detail .text-block .short-info .list-productspecs li:last-child {
    border-bottom: 0; }

#product-detail .text-block .short-info .col-md-5,
#product-detail .text-block .short-info .col-md-6 {
  padding: 0; }

#product-detail .text-block .tab-container {
  float: left;
  width: 100%;
  display: block;
  margin: 60px 0 0 0; }

#product-detail .text-block .tab-content {
  padding: 20px 15px 0 15px; }
  #product-detail .text-block .tab-content h2 {
    font-size: 24px; }

#product-detail .text-block .nav-tabs {
  padding: 0 !important;
  margin: 0; }

#products-overview .heading {
  float: left;
  width: 100%;
  position: relative;
  display: block;
  margin: 0 0 30px 0;
  padding: 0 0 0 15px; }

#products-overview .list .item a.thumbnail .extra {
  height: 300px;
  min-height: 300px; }

.contact-intro ul {
  margin: 0 0 45px 0;
  padding: 0;
  list-style: none; }
  .contact-intro ul li {
    position: relative;
    padding: 5px 0 5px 35px;
    font-size: 18px;
    line-height: 36px;
    font-family: "Source Sans Pro", sans-serif; }
    .contact-intro ul li:before {
      content: "\f00c";
      font-family: FontAwesome;
      position: absolute;
      left: 0;
      top: 4px;
      font-size: 22px;
      color: #206f92; }

.table-times {
  margin: 10px 0; }
  .table-times tr td {
    font-size: 18px;
    line-height: 36px;
    font-family: "Source Sans Pro", sans-serif;
    padding: 0; }
    .table-times tr td:first-child {
      width: 45px; }
    .table-times tr td:last-child {
      font-weight: 400; }

.contact h1 {
  margin: 0 0 8px 0;
  display: block; }

.contact .address h3 {
  margin-bottom: 10px; }

.contact .address p {
  margin-bottom: 24px; }

.contact .contact-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  .contact .contact-list li {
    float: left;
    width: 100%;
    position: relative;
    font-size: 21px;
    margin: 5px 0;
    padding: 10px 0 10px 65px; }
    .contact .contact-list li a {
      color: #206f92; }
      .contact .contact-list li a:hover, .contact .contact-list li a:focus {
        color: #ded662; }
    .contact .contact-list li:before {
      content: "";
      font-family: FontAwesome;
      position: absolute;
      left: 0;
      top: 3px;
      padding-top: 5px;
      border: 3px solid #206f92;
      border-radius: 50%;
      color: #206f92;
      font-size: 20px;
      width: 45px;
      height: 45px;
      text-align: center; }
    .contact .contact-list li:first-child:before {
      content: "\f095";
      padding-top: 6px; }
    .contact .contact-list li:last-child:before {
      content: "\f003"; }

#maps {
  width: 100%;
  height: 600px;
  overflow: hidden; }
  #maps iframe {
    filter: grayscale(75%); }

@media only screen and (max-width: 1499px) {
  .container-fluid {
    max-width: 1300px; }
  .list .item .thumbnail .extra {
    height: 400px;
    min-height: 400px; }
    .list .item .thumbnail .extra .short {
      min-height: 225px;
      height: 225px; }
  .navbar-fixed-top .navbar-header .brand {
    width: 350px;
    margin-top: 15px; }
  .navbar-fixed-top .navbar-right {
    margin-top: 30px; }
    .navbar-fixed-top .navbar-right li a {
      font-size: 20px;
      padding: 12px 0;
      margin: 0 18px; }
    .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large {
      max-width: 1300px; }
  .navbar-fixed-top.stick {
    height: 88px;
    top: 25px; }
    .navbar-fixed-top.stick .navbar-right {
      margin-top: 21px; } }

@media only screen and (max-width: 1339px) {
  .container-fluid {
    max-width: 1170px; }
  .navbar-fixed-top .navbar-header .brand {
    width: 300px; }
  .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large {
    max-width: 1170px; }
  .list .item .thumbnail .extra {
    height: 445px;
    min-height: 445px; }
    .list .item .thumbnail .extra .short {
      min-height: 270px;
      height: 270px; } }

@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 48px; }
  section.padded {
    padding: 80px 0; }
  #slider-container {
    height: 400px; }
    #slider-container #slider {
      min-height: 400px; }
      #slider-container #slider .owl-slide {
        height: 400px !important; }
  header #header-image {
    height: 400px !important; }
  .navbar-fixed-top .navbar-header .brand {
    width: 320px;
    margin-top: 15px; }
  .navbar-fixed-top .navbar-right {
    margin-top: 30px; }
    .navbar-fixed-top .navbar-right li a {
      font-size: 20px;
      padding: 12px 0;
      margin: 0 12px; }
    .navbar-fixed-top .navbar-right li.dropdown-large .dropdown-menu-large {
      max-width: 100%;
      width: 98%; }
  .navbar-fixed-top.stick {
    height: 88px;
    top: 25px; }
    .navbar-fixed-top.stick .navbar-right {
      margin-top: 21px; }
  #home-catalog {
    padding: 60px 80px; }
    #home-catalog .text-block {
      padding-right: 40px; }
    #home-catalog .usp ul li {
      margin: 0 50px 30px 0; }
    #home-catalog .download-buttons {
      margin-top: 50px; }
  #home-products {
    padding: 80px; }
  #news-overview-cards {
    padding: 0 80px; }
  #contact-info .col-lg-6 {
    width: 66.66666667%;
    margin-left: 16.66666667%;
    max-width: 815px; }
  #contact-info span.address {
    width: 50%;
    float: left;
    display: block; }
    #contact-info span.address h3 {
      font-size: 32px;
      margin: 0 0 10px 0; }
  #contact-info .contact-list {
    width: 50%;
    float: left; }
  #contact-info #contactForm {
    padding: 0;
    margin-top: 40px; }
  #maps {
    height: 500px; }
    #maps iframe {
      height: 500px !important; } }

@media only screen and (max-width: 1099px) {
  .navbar-fixed-top .navbar-header .brand {
    width: 280px;
    margin-top: 15px; }
  .navbar-fixed-top .navbar-right {
    margin-top: 30px; }
    .navbar-fixed-top .navbar-right li a {
      font-size: 18px;
      padding: 12px 0;
      margin: 0 10px; }
  .navbar-fixed-top.stick {
    height: 88px;
    top: 25px; }
    .navbar-fixed-top.stick .navbar-right {
      margin-top: 21px; } }

@media only screen and (max-width: 991px) {
  #slider-container {
    height: 320px; }
    #slider-container #slider {
      min-height: 320px; }
      #slider-container #slider .owl-slide {
        height: 320px !important; }
  header #header-image {
    height: 320px !important; }
  .navbar-fixed-top .navbar-header .brand {
    margin-top: 5px;
    width: 235px; }
  .navbar-fixed-top .navbar-right {
    margin-top: 24px; }
    .navbar-fixed-top .navbar-right li:first-child {
      display: none; }
    .navbar-fixed-top .navbar-right li a {
      font-size: 17px;
      margin: 0 12px; }
  .navbar-fixed-top.stick .navbar-header .brand {
    width: 235px;
    margin-top: 0px; }
  section.padded {
    padding: 60px 35px; }
  #home-catalog {
    padding: 60px 35px; }
    #home-catalog .text-block {
      padding-right: 0; }
    #home-catalog .usp ul li {
      font-size: 22px; }
      #home-catalog .usp ul li.service {
        padding-left: 45px; }
        #home-catalog .usp ul li.service img {
          width: 36px;
          height: 30px; }
  .sidebar {
    background: none;
    width: 100%;
    margin: 0 0 30px 0; }
    .sidebar h4 {
      margin: 0 0 15px 0; }
    .sidebar #sidebar-list {
      width: 100%; }
  #home-products {
    padding: 60px 35px; }
  #news-overview-cards {
    padding: 0px 35px; }
  #contact-info .col-lg-6 {
    width: 100%;
    margin-left: 0;
    max-width: 100%; } }

@media only screen and (max-width: 767px) {
  .navbar-fixed-top {
    height: 75px;
    transition: ease all 0.25s;
    position: fixed;
    top: 25px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
    background: white; }
    .navbar-fixed-top button {
      background: transparent;
      border: 0;
      float: right;
      width: 45px;
      height: 35px;
      position: relative;
      margin: 22px 20px 0;
      transform: rotate(0deg);
      transition: .5s ease-in-out;
      cursor: pointer; }
      .navbar-fixed-top button span {
        display: block;
        position: absolute;
        height: 4px;
        border-radius: 4px;
        width: 100%;
        background: #206f92;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out; }
      .navbar-fixed-top button span:nth-child(1) {
        top: 0; }
      .navbar-fixed-top button span:nth-child(2) {
        top: 12px; }
      .navbar-fixed-top button span:nth-child(3) {
        top: 24px; }
    .navbar-fixed-top .menu-toggle.open span:nth-child(1) {
      top: 12px;
      transform: rotate(135deg); }
    .navbar-fixed-top .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px; }
    .navbar-fixed-top .menu-toggle.open span:nth-child(3) {
      top: 12px;
      transform: rotate(-135deg); }
    .navbar-fixed-top .navbar-header .brand {
      margin-left: 20px;
      margin-top: 15px;
      width: 200px;
      transition: ease all 0.25s; }
      .navbar-fixed-top .navbar-header .brand img {
        height: 40px !important;
        width: 200px !important; }
    .navbar-fixed-top #navbar {
      border-color: transparent;
      border: 0; }
      .navbar-fixed-top #navbar .navbar-right {
        margin: 7.5px -15px;
        background: white;
        transition: none; }
        .navbar-fixed-top #navbar .navbar-right li {
          border-bottom: 1px solid #f5f5f5; }
          .navbar-fixed-top #navbar .navbar-right li:first-child {
            display: block; }
          .navbar-fixed-top #navbar .navbar-right li a {
            color: #206f92;
            text-shadow: none;
            transition: none; }
            .navbar-fixed-top #navbar .navbar-right li a:before {
              content: "";
              width: 0;
              height: 0;
              transition: none; }
            .navbar-fixed-top #navbar .navbar-right li a:hover:before, .navbar-fixed-top #navbar .navbar-right li a:focus:before {
              content: "";
              width: 0;
              height: 0;
              transition: none; }
          .navbar-fixed-top #navbar .navbar-right li.active {
            background: #206f92; }
            .navbar-fixed-top #navbar .navbar-right li.active a {
              text-shadow: none;
              color: white; }
          .navbar-fixed-top #navbar .navbar-right li.dropdown-large .dropdown-menu-large {
            padding: 0;
            width: 100%; }
            .navbar-fixed-top #navbar .navbar-right li.dropdown-large .dropdown-menu-large li {
              background: #eaf5fa; }
              .navbar-fixed-top #navbar .navbar-right li.dropdown-large .dropdown-menu-large li a {
                text-transform: lowercase; }
              .navbar-fixed-top #navbar .navbar-right li.dropdown-large .dropdown-menu-large li:last-child a {
                border: 0; }
    .navbar-fixed-top .navbar-nav > .open > a,
    .navbar-fixed-top .navbar-nav > .open > a:focus,
    .navbar-fixed-top .navbar-nav > .open > a:hover {
      background: transparent; }
    .navbar-fixed-top.stick {
      top: 25px;
      height: 75px; }
      .navbar-fixed-top.stick .navbar-header .brand {
        width: 200px;
        margin-top: 15px; }
      .navbar-fixed-top.stick button span {
        background: #206f92; }
  #slider-container {
    height: 210px;
    margin-top: 95px; }
    #slider-container #slider {
      min-height: 210px; }
      #slider-container #slider .owl-slide {
        height: 210px !important; }
  header #header-image {
    height: 210px !important; }
  h1 {
    font-size: 32px; }
  .contact h1 {
    margin: 0 0 30px 0; }
  section.padded {
    padding: 45px 8px; }
  #home-catalog {
    padding: 45px 8px; }
    #home-catalog .download-buttons a {
      display: block;
      width: 100%;
      margin: 0 0 15px 0; }
  #home-products {
    padding: 45px 8px; }
    #home-products h2 {
      margin: 0 0 30px;
      text-align: left;
      font-size: 36px; }
  #product-detail .text-block .tab-container {
    margin: 0; }
  #product-detail .text-block .tab-content {
    padding: 15px 0 0 0;
    display: block;
    overflow: auto; }
    #product-detail .text-block .tab-content p + ul {
      margin: 0; }
    #product-detail .text-block .tab-content ul + p {
      margin-top: 30px; }
    #product-detail .text-block .tab-content h2 {
      font-size: 21px; }
    #product-detail .text-block .tab-content .tab-pane {
      margin-top: 25px;
      float: left;
      display: block;
      overflow: hidden; }
      #product-detail .text-block .tab-content .tab-pane .list-photos {
        overflow: hidden;
        display: block; }
        #product-detail .text-block .tab-content .tab-pane .list-photos li {
          overflow: hidden; }
          #product-detail .text-block .tab-content .tab-pane .list-photos li a {
            margin: 0 0 10px 0; }
          #product-detail .text-block .tab-content .tab-pane .list-photos li:nth-child(odd) {
            padding-right: 5px; }
          #product-detail .text-block .tab-content .tab-pane .list-photos li:nth-child(even) {
            padding-left: 5px; }
  #product-detail .text-block #omschrijving {
    margin-top: 0; }
  #product-detail .text-block #specificaties {
    width: 100%; }
    #product-detail .text-block #specificaties ul {
      width: 100%; }
  #product-detail .text-block .nav-tabs li a {
    padding: 7px 10px;
    font-size: 18px; }
  #product-detail .text-block .short-info h1 {
    margin: 0;
    font-size: 30px;
    border-bottom: 1px solid aliceblue;
    padding-bottom: 15px; }
  .card-list li {
    padding-left: 0;
    padding-right: 0; }
    .card-list li .thumbnail .owl-slide .image {
      height: 200px !important; }
    .card-list li .thumbnail span {
      width: 100%; }
    .card-list li .thumbnail:hover span {
      padding: 9px 10px 12px 10px; }
      .card-list li .thumbnail:hover span:after {
        content: none; }
  .card-list li:nth-child(2n+1) {
    padding-left: 0;
    padding-right: 0; }
  .card-list li:nth-child(2n) {
    padding-right: 0;
    padding-left: 0; }
  #news-overview-cards {
    padding: 0 20px;
    margin: 0 0 30px; }
    #news-overview-cards .masonry-row .item {
      padding: 0; }
      #news-overview-cards .masonry-row .item h2 {
        font-size: 26px; }
      #news-overview-cards .masonry-row .item .desc {
        display: none; }
  .list .item .thumbnail .extra {
    height: auto;
    min-height: 1px;
    padding: 25px 25px 50px 25px; }
    .list .item .thumbnail .extra .short {
      min-height: 1px;
      height: auto; }
  .list .item .thumbnail .album-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #searchForm {
    width: 90%; }
    #searchForm .form-control {
      width: 100%; }
  #contact-info span.address {
    width: 100%; }
  #contact-info .contact-list {
    width: 100%; }
  .form-small,
  .form-medium {
    width: 100%; }
  .form-small {
    padding-right: 0px; }
  .form-medium {
    padding-left: 0px; }
  .form-small + .form-medium {
    margin-top: 8px; }
  footer .footer-logo {
    text-align: center;
    float: none;
    margin: 0 auto 5px auto;
    width: 250px; }
  footer .footer-address {
    text-align: center; }
    footer .footer-address ul {
      display: inline-table;
      margin: 0;
      float: none; }
      footer .footer-address ul li {
        display: inline-block;
        *display: inline;
        /*IE7*/
        *zoom: 1;
        /*IE7*/
        width: 100%;
        font-size: 20px;
        line-height: 36px; }
        footer .footer-address ul li:before {
          content: ""; } }
