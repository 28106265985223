@media only screen and (max-width : 1199px) {
  h1 {
    font-size:48px;
  }
  section {
    &.padded {
      padding:80px 0;
    }
  }
  #slider-container {
    height:400px;
    #slider {
      min-height:400px;
      .owl-slide {
        height:400px !important;
      }
    }
  }
  header {
    #header-image {
      height:400px !important;
    }
  }
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        width:320px;
        margin-top:15px;
      }
    }
    .navbar-right {
      margin-top:30px;
      li {
        a {
          font-size:20px;
          padding:12px 0;
          margin:0 12px;
        }
        &.dropdown-large {
          .dropdown-menu-large {
            max-width:100%;
            width:98%;
          }
        }
      }
    }
    &.stick {
      height:88px;
      top:25px;
      .navbar-right {
        margin-top:21px;
      }
    }
  }
  #home-catalog {
    padding:60px 80px;
    .text-block {
      padding-right:40px;
    }
    .usp ul li {
      margin:0 50px 30px 0;
    }
    .download-buttons {
      margin-top:50px;
    }
  }
  #home-products {
    padding:80px;
  }

  #news-overview-cards {
    padding:0 80px;
  }

  #contact-info {
    .col-lg-6 {
      width:66.66666667%;
      margin-left:16.66666667%;
      max-width:815px;
    }
    span.address {
      width:50%;
      float:left;
      display:block;
      h3 {
        font-size: 32px;
        margin: 0 0 10px 0;
      }
    }
    .contact-list {
      width:50%;
      float:left;
    }
    #contactForm {
      padding:0;
      margin-top:40px;
    }
  }
  #maps {
    height:500px;
    iframe {
      height:500px !important;
    }
  }
}

@media only screen and (max-width : 1099px) {
  .navbar-fixed-top {
    .navbar-header {
      .brand {
        width: 280px;
        margin-top: 15px;
      }
    }
    .navbar-right {
      margin-top: 30px;
      li {
        a {
          font-size: 18px;
          padding: 12px 0;
          margin: 0 10px;
        }
      }
    }
    &.stick {
      height: 88px;
      top: 25px;
      .navbar-right {
        margin-top: 21px;
      }
    }
  }
}